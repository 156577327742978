import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import Avatar from 'components/common/Avatar';
import {
  uploadImage,
  updatePictureUrl,
} from 'actions/userProfile/action';
import Preferences from 'containers/robocampus/Preferences';
import ResumeUpload from 'containers/roboroy/ResumeUpload/ResumeUpload';
import Toast from 'components/common/Toast';
import { count } from '@wordpress/wordcount';
import './registerCandidate.css';
import {
  getCandidateProfile,
  editCandidateProfile,
} from 'actions/talentSearch/candidatesList';
import {
  getAllFaculties,
} from 'actions/candidate/CandidateDetails';
import {
  getCitiesList,
} from 'actions/CitiesDetails';
import {
  TextArea, Toggle, RadioButtonGroup, RadioButton,
  Button, InlineNotification,
  InlineLoading,
  Checkbox,
} from 'carbon-components-react';
import PublicCandidateInfo from './publicCandidateInfo';

class GeipDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutBusiness: '',
      startedBusinessOutsideCourse: false,
      plannedOverseasTrip: false,
      travelledOverseasAlone: false,
      geipSelectionAchievement: '',
      geipReasonToChoose: '',
      marketingGeipQuestion: {},
      canWorkInAsean: '',
      cities: [],
      activeModal: false,
      uploadComplete: false,
      showErrorNotif: false,
      maxWords: 0,
      showErrorCountNotif: false,
      cityPreferences: [],
      showEmailErr: false,
      showLoader: false,
      shouldRedirect: false,
      consentPii: false,
    };
    this.notifRef = React.createRef();
  }

  componentDidMount() {
    const {
      profileId,
    } = this.props;
    const data = {
      activePage: 1,
      itemsPerPage: -1,
      keyword: '',
    };
    this.props.getCitiesList(data);
    if (profileId) {
      this.setState({
        profileLoading:true
      },()=>{
        this.props.getCandidateProfile(profileId).then((res) => {
          this.setState({profileLoading:false})
          if (res && !res.error) {
            this.handleValidationStages();
            const {
              citypreferences,
              studentprofile: {
                schoolId,
              } = {},
              profile: {
                consentPii,
              } = {},
            } = res || {};
            const facultyData = {
              schoolId,
              facultyActivePage: 1,
              facultyItemsPerPage: 50,
              facultySearchKey: null,
            };
            this.props.getAllFaculties(facultyData);
            const {
              studentprofile: {
                aboutBusiness,
                canWorkInAsean,
                chineseHigherOGrade,
                chineseOGrade,
                geipReasonToChoose,
                geipSelectionAchievement,
                marketingGeipQuestion,
                startedBusinessOutsideCourse,
                travelledOverseasAlone,
                plannedOverseasTrip,
              } = {},
            } = res || {};
            let cityPreferences = [];
            if (citypreferences && Array.isArray(citypreferences)
              && citypreferences.length > 0) {
              cityPreferences = citypreferences.map((cit) => {
                const {
                  cities: {
                    cityName,
                  } = {},
                } = cit || {};
                return cityName;
              });
            }
            this.setState({
              aboutBusiness,
              canWorkInAsean,
              chineseHigherOGrade,
              chineseOGrade,
              geipReasonToChoose,
              marketingGeipQuestion,
              geipSelectionAchievement,
              startedBusinessOutsideCourse,
              cityPreferences,
              consentPii: consentPii === null ? false : consentPii,
            }, () => {
              if (plannedOverseasTrip !== null && plannedOverseasTrip !== undefined) {
                this.setState({
                  plannedOverseasTrip,
                });
              }
              if (travelledOverseasAlone !== null && travelledOverseasAlone !== undefined) {
                this.setState({
                  travelledOverseasAlone,
                });
              }
            });
          }
        });
      });
    }
  }

  onToggle = (e, name) => {
    this.setState({ [name]: !this.state[name] });
  }

  handleText = (e) => {
    const { target: { name = '', value = '' } } = e;
    const finalVal = value && value.toString() && value.trimLeft();
    if(name == 'othersAnswer') {
      const { marketingGeipQuestion } = this.state || {};
      this.setState({
        marketingGeipQuestion: {
          ...marketingGeipQuestion,
          [name]: value,
        }
      });
    } else {
      this.setState({ [name]: finalVal });
    }
  }

  onRadioGroupChange = (value, name) => {
    this.setState({ [name]: value });
  }

  changeImage = (element) => {
    const { user: { userId } = {} } = this.props;
    const fileData = element.target.files;
    const file = new FormData();
    file.append('file', fileData[0]);
    this.setState({
      showLoader: true,
    }, () => {
      this.props.uploadImage(file).then((res) => {
        // window.location.reload();
        const { result } = res || {};
        const { files } = result || {};
        const { file } = files || {};
        const { name } = (file && file[0]) || {};
        // this.setState({
        //   image: name,
        // });
        if (name) {
          this.props.updatePictureUrl(userId, name)
            .then(() => {
              window.location.reload();
            })
            .catch(e => console.error('Error while updating avatar:', e));
        }
      });
    });
  }

  onDropdownChange = (value, params) => {
    if (value === undefined) return;
    if (params === 'cities') {
      if (value.length <= 3) {
        this.setState({ [params]: value });
      }
    } else {
      this.setState({ [params]: value });
    }
  }

  activateModal = () => {
    this.setState({
      activeModal: true,
    });
  };

  dismissModal = (flag) => {
    this.setState({
      activeModal: false,
      uploadResumeClicked: flag,
    });
  };

  handleSubmitConsent = () => {
    const {
      consentPii,
    } = this.state;
    const {
      push,
      candidateProfile,
      profileId
    } = this.props;
    const {
      documents,
    } = candidateProfile || {};
    if (documents && Array.isArray(documents) && documents.length <= 0) {
      this.setState({
        showDocsErr: true,
      });
    } else if (consentPii !== true) {
      this.setState({
        showConsetErr: true,
        showDocsErr: false,
      });
    } else {
      // const {
      //   profile: {
      //     profileId,
      //   } = {},
      // } = candidateProfile || {};
      const data = {
        profileId,
        consentPii: this.state.consentPii,
      };
      this.props.editCandidateProfile(data).then((res) => {
        if (res && !res.error) {
          push(`/app/campus/candidate/register/profile/${profileId}/stage/index/2`);
        }
      }).catch(e => console.error('Error:::::', e));
    }
  }

  toastSuccess = (returnValue) => {
    const {
      isEmailExists,
      error,
    } = returnValue || {};
    const {
      message,
    } = error || {};
    if (message === 'Address present for the profile..') {
      this.setState({
        showAddrErr: true,
        showEmailErr: false,
      });
    } else {
      setTimeout(() => {
        window.location.reload();
        this.setState({ uploadComplete: true, uploadResumeClicked: false });
      }, 5000);
    }
  };

  refresh = (partialRefresh) => {
    // setTimeout(() => {
    //   });
    // }, 1000);
  };

  handleOnclickNextButton = () => {
    const {
      aboutBusiness = '',
      startedBusinessOutsideCourse = false,
      plannedOverseasTrip = false,
      travelledOverseasAlone = false,
      geipSelectionAchievement = '',
      geipReasonToChoose = '',
      marketingGeipQuestion,
      canWorkInAsean = '',
      cityPreferences = [],
    } = this.state;
    const { isOthers, othersAnswer, selectedMarketCheckboxes } = marketingGeipQuestion || {};
    if (startedBusinessOutsideCourse === true && (!aboutBusiness || aboutBusiness === '' || aboutBusiness === null || aboutBusiness === undefined)) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (geipSelectionAchievement
      && geipSelectionAchievement !== null
      && geipSelectionAchievement !== ''
      && geipSelectionAchievement !== undefined
      && count(geipSelectionAchievement, 'words', {}) > 50) {
      this.setState({
        showErrorCountNotif: true,
        showErrorNotif: false,
        maxWords: 50,
      });
    } else if (geipReasonToChoose
      && geipReasonToChoose !== null
      && geipReasonToChoose !== ''
      && geipReasonToChoose !== undefined
      && count(geipReasonToChoose, 'words', {}) > 100) {
      this.setState({
        showErrorCountNotif: true,
        showErrorNotif: false,
        maxWords: 100,
      });
    } else if (!geipReasonToChoose || geipReasonToChoose === null || geipReasonToChoose === '' || geipReasonToChoose === undefined) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!selectedMarketCheckboxes || (selectedMarketCheckboxes && JSON.stringify(selectedMarketCheckboxes) == '{}')) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (isOthers && (!othersAnswer || !(othersAnswer && othersAnswer.trim()))) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    }
    // else if (!canWorkInAsean || canWorkInAsean === null || canWorkInAsean === undefined) {
    //   this.setState({
    //     showErrorNotif: true,
    //   }, () => {
    //     const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
    //     && this.notifRef.current.focus();
    //   });
    // }
    else if (plannedOverseasTrip === null || plannedOverseasTrip === undefined) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (travelledOverseasAlone === null || travelledOverseasAlone === undefined) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    }
    // else if (cityPreferences && Array.isArray(cityPreferences) && cityPreferences.length <= 0) {
    //   this.setState({
    //     showErrorNotif: true,
    //   }, () => {
    //     const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
    //     && this.notifRef.current.focus();
    //   });
    // }
    else {
      const {
        push,
        profileId
      } = this.props;
      if (push) {
        push(`/app/campus/candidate/register/profile/${profileId}/stage/index/3`);
      }
    }
  }

  setPreferences = (data, name) => {
    this.setState({ [name]: data });
  }
  handlePreviousButtonClick = () => {
    const {
      push,
    } = this.props;
    if (push) {
      push('/app/campus/candidate');
    }
  }
  handlePreviousButtonClickBackToStep = () => {
    const {
      push,
      profileId
    } = this.props;
    if (push) {
      push(`/app/campus/candidate/register/profile/${profileId}/stage/index/0`);
    }
  }
  handleClickNextBtn = () => {
    const {
      user: {
        picture,
      } = {},
      push,
      candidateProfile,
      profileId
    } = this.props || {};
    const {
      documents,
    } = candidateProfile || {};
    if (!picture) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (documents && Array.isArray(documents) && documents.length > 0 && push) {
      push(`/app/campus/candidate/register/profile/${profileId}/stage/index/2`);
    } else if (push) {
      push(`/app/campus/candidate/register/profile/${profileId}/stage/index/1`);
    }
  }

  handleValidationStages = () => {
    const {
      indexId,
      push,
      user: {
        picture,
      } = {},
      candidateProfile,
      needNotRedirect,
      profileId
    } = this.props;
    const {
      studentprofile,
      documents,
    } = candidateProfile || {};
    const {
      startedBusinessOutsideCourse,
      aboutBusiness,
      plannedOverseasTrip = false,
      travelledOverseasAlone = false,
      geipSelectionAchievement = '',
      geipReasonToChoose = '',
      canWorkInAsean = '',
      cityPreferences = [],
    } = studentprofile || {};
    let redirectIndex = 0;
    let shouldRedirect = false;
    switch (indexId) {
      case '1':
        if (!picture || picture === null || picture === undefined) {
          redirectIndex = 0;
          shouldRedirect = true;
        } else {
          shouldRedirect = false;
        }
        break;
      case '2':
        if (!picture || picture === null || picture === undefined) {
          redirectIndex = 0;
          shouldRedirect = true;
        } else if (!documents || (documents && Array.isArray(documents) && documents.length <= 0)) {
          redirectIndex = 1;
          shouldRedirect = true;
        } else {
          shouldRedirect = false;
        }
        break;
      case '3':
        if (!picture || picture === null || picture === undefined) {
          redirectIndex = 0;
          shouldRedirect = true;
        } else if (!documents || (documents && Array.isArray(documents) && documents.length <= 0)) {
          redirectIndex = 1;
          shouldRedirect = true;
        } else if ((startedBusinessOutsideCourse === true
          && (!aboutBusiness || aboutBusiness === '' || aboutBusiness === null || aboutBusiness === undefined))
          // || plannedOverseasTrip === null || plannedOverseasTrip === undefined || travelledOverseasAlone === null
          // || travelledOverseasAlone === undefined
          || geipSelectionAchievement === null
          || geipSelectionAchievement === undefined
          || geipReasonToChoose === null
          || geipReasonToChoose === undefined
          // || canWorkInAsean === null
          // || canWorkInAsean === undefined
          || !cityPreferences
          || (cityPreferences && Array.isArray(cityPreferences) && cityPreferences.length <= 0)) {
          redirectIndex = 2;
          shouldRedirect = true;
        } else {
          shouldRedirect = false;
        }
        break;
      default:
        break;
    }
    if (shouldRedirect === true && push && !needNotRedirect) {
      push(`/app/campus/candidate/register/profile/${profileId}/stage/index/${redirectIndex}`);
    }
    return shouldRedirect;
  }


  handleSensitiveConsetChange = () => {
    this.setState({
      consentPii: !this.state.consentPii,
    });
  }

  handleChangeMarketCheckbox = (isSelected, value, label) => {
    const { marketingGeipQuestion } = this.state || {};
    let { selectedMarketCheckboxes, othersAnswer, isOthers } = marketingGeipQuestion || {};
    isOthers = ((value != 'others' && isOthers) || (value == 'others' && isSelected)) ? true : false;
    othersAnswer = isOthers ? othersAnswer : '';
    if(!selectedMarketCheckboxes) { selectedMarketCheckboxes = {}; }
    if(isSelected) {
      selectedMarketCheckboxes[value] = label;
    } else {
      delete selectedMarketCheckboxes[value];
    }
    this.setState({
      marketingGeipQuestion: {
        ...marketingGeipQuestion,
        isOthers,
        othersAnswer,
        selectedMarketCheckboxes: {
          ...selectedMarketCheckboxes,
        },
      }
    })
  }

  render() {
    const {
      disabled = false,
      t,
      indexId,
      taskId,
      push,
      candidateProfile,
      user,
      loading,
      profileId
    } = this.props;

    const {
      studentprofile: {
        isAppSubmitted,
      } = {},
      documents,
      userProfile = {},
    } = candidateProfile || {};
    const { userinfo = {}} = userProfile || {};
    const { picture } = userinfo || {};

    const {
      aboutBusiness = '',
      startedBusinessOutsideCourse = false,
      plannedOverseasTrip = false,
      travelledOverseasAlone = false,
      geipSelectionAchievement = '',
      geipReasonToChoose = '',
      canWorkInAsean = '', maxWords,
      cities = [], showErrorCountNotif,
      uploadResumeClicked, showErrorNotif,
      activeModal, uploadComplete,
      cityPreferences, showEmailErr, showLoader,
      showAddrErr, showConsetErr, showDocsErr, profileLoading,
      marketingGeipQuestion, marketingGeipQuestionInvalid
    } = this.state;
    let { 
      answers, isOthers, othersAnswer, othersAnswerInvalid, selectedMarketCheckboxes
    } = marketingGeipQuestion || {};
    const selectedMarketCheckboxesValues = selectedMarketCheckboxes && Object.keys(selectedMarketCheckboxes);
    const allCities = [
      { value: '1', label: 'Bangkok' },
      { value: '2', label: 'Ho Chi Minh' },
      { value: '3', label: 'Jakarta' },
      { value: '4', label: 'Beijing' },
      { value: '5', label: 'Hong Kong' },
      { value: '6', label: 'Shanghai' },
      { value: '7', label: 'Helsinki' },
      { value: '8', label: 'London' },
      { value: '9', label: 'Silicon Valley' },
      { value: '10', label: 'Sydney' },
    ];

    const geipMarketCheckboxes = [
      {label: "Sharing of GEIP during lectures arranged by the school", value: "sharingOfGeip"},
      {label: "Personalised emails/Teams messages to me", value: "emailsAndTeams"},
      {label: "Electronic digital mailers in emails", value: "digitalMailers"},
      {label: "Digital displays around the campus", value: "digitalDisplays"},
      {label: "Social media platforms e.g. Tiktok, Instagram", value: "socialMediaPlatforms"},
      {label: "Word of mouth (e.g. friends, seniors)", value: "wordOfMouth"},
      {label: "Others", value: "others"},
    ]

    return (
      <Fragment>
        <JDProcessOverlay show={loading || showLoader || profileLoading} message="processing..." />
        {
          indexId !== null && indexId!==undefined && Number(indexId) === 0 && (
            <Fragment>
              <div className="h5 font-weight-bold mb-2 text-center">
                {t('uploadImgTxt')}
              </div>
              <input
                style={{ height: 0, opacity: 0 }}
                ref={this.notifRef}
              />
                {
                  showErrorNotif === true && (
                    <InlineNotification lowContrast className="w-100" kind="error" title={t('imageErrMsg')} subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showErrorNotif: false })} />
                  )
                }
              <div className="col-xs-12 col-sm-12 col-md-2 mx-auto text-center">
                {!disabled && <label htmlFor="photo-upload-input" className="mx-auto">
                  <Avatar
                    user={{
                      firstname: user && user.firstname,
                      lastname: user && user.lastname,
                      picture: picture ? picture : null,
                    }}
                    size="100"
                    classNameInfo="rounded-circle"
                  />
                  <span className="upload-span d-block xpa-link text-capitalize" hidden={picture ? true : false}>{t('uploadImage')}</span>
                </label>}
                {disabled && <Avatar
                  user={{
                    firstname: user && user.firstname,
                    lastname: user && user.lastname,
                    picture: picture ? picture : null,
                  }}
                  size="100"
                  classNameInfo="rounded-circle"
                />}
                <input
                  accept=".png,.jpg,.jpeg,.gif,image/gif,image/jpg,image/jpeg,image/png"
                  id="photo-upload-input"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={this.changeImage}
                />
                <input
                  id="photo-upload-input"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={this.changeImage}
                />
              </div>
              {!disabled && <div className="d-flex w-100 justify-content-center mt-5">
                <div>
                  <Button onClick={this.handlePreviousButtonClick} className="mr-2" kind="tertiary" small>{t('previous')}</Button>
                </div>
                <div>
                  <Button onClick={this.handleClickNextBtn} kind="primary" small>{t('next')}</Button>
                </div>
              </div>}
              {/* <div className="text-center">
                <Button
                  small
                  kind="primary"
                  onClick={this.handleClickNextBtn}
                >
                  {t('next')}
                </Button>
              </div> */}
              {/* <JDProcessOverlay show={loading || showLoader} message="processing..." /> */}
            </Fragment>
          )
        }
        <div className="m-0 p-0">
          {
            indexId!==null && indexId!==undefined && Number(indexId) === 1
              && (
                  <div className="bx--form-item modal-footer xpa-banner">
                    {uploadResumeClicked === true && (
                      <div className="toastCandidate">
                        <Toast taskId={taskId} successAction={this.toastSuccess} partialRefresh={this.refresh} />
                      </div>
                    )}
                    <div className="h4 dont-weight-bold">
                      {t('uploadResume')}
                    </div>
                    <div>
                      <p>
                        {t('uploadResumeData')}
                      </p>
                    </div>
                    {
                      showDocsErr === true && (
                        <InlineNotification lowContrast className="w-100" kind="error" title={t('resumeErr')} subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showDocsErr: false })} />
                      )
                    }
                    {
                      showConsetErr === true && (
                        <InlineNotification lowContrast className="w-100" kind="error" title={t('consentErr')} subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showConsetErr: false })} />
                      )
                    }
                      {
                        showAddrErr === true && (
                          <InlineNotification lowContrast className="w-100" kind="error" title={t('addrError')} subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showAddrErr: false })} />
                        )
                      }
                      {
                        showEmailErr === true && (
                          <InlineNotification lowContrast className="w-100" kind="error" title={t('emailError')} subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showEmailErr: false })} />
                        )
                      }
                    <div className="text-center mb-2 font-weight-bold">
                      {t('uploadResumeMsg1')}
                    </div>
                    {
                      ((documents && Array.isArray(documents) && documents.length <= 0) || (!documents)) && (
                        <div className="justify-content-center">
                          <ResumeUpload pageType="candidate-profile" isStudentRegPage candidateProfile={candidateProfile} dismiss={this.dismissModal} active={activeModal} multipleResume={false} />
                          <Button disabled={uploadResumeClicked} onClick={this.activateModal}>{t('uploadResume')}</Button>
                        </div>
                      )
                    }
                    {/* <div className="w- text-center mt-3">
                      <p className="bx--type-zeta">{t('uploadResumeSampleUrl')} <a target="_blank" href="https://x0pa-my.sharepoint.com/personal/kenny_x0pa_com/_layouts/15/Doc.aspx?sourcedoc=%7B6052143f-a6cc-4e6b-94d0-d88ca448d841%7D&action=default&slrid=88d94c9f-3071-a000-c95f-7c1c11fb85e4&originalPath=aHR0cHM6Ly94MHBhLW15LnNoYXJlcG9pbnQuY29tLzp3Oi9wL2tlbm55L0VUOFVVbURNcG10T2xORFlqS1JJMkVFQldadlNVRzBPLXJLaGF6TC1GV1RKcFE_cnRpbWU9VHQ5RUZwX3ExMGc&cid=0145dfd6-29d4-4783-a3af-9f56c0bbb7b2">{t('clickHere')}</a></p>
                    </div> */}
                    <div className="mt-2">
                      <Checkbox
                        name="consentPii"
                        id="consentPii"
                        labelText={t('parseSensitiveConsent')}
                        value={this.state.consentPii}
                        checked={this.state.consentPii}
                        onChange={this.handleSensitiveConsetChange}
                      />
                    </div>
                    {uploadResumeClicked && <div><InlineLoading className="xpa-inline-loader" success={uploadComplete} description={t('uploadResumeMsg')} /></div>}
                    <div>
                      <Button kind="primary" size="small" onClick={this.handleSubmitConsent}>
                        {t('next')}
                      </Button>
                    </div>
                  </div>
              )
          }
          {
            indexId!==null && indexId!==undefined && Number(indexId) === 2
              && (
                <Fragment>
                  <div className="bx--row m-0">
                    {
                      isAppSubmitted !== true && (
                        <div className="font-weight-bold h5">
                          {t('geipMsg')}
                        </div>
                      )
                    }
                  </div>
                  <input
                    style={{ height: 0, opacity: 0 }}
                    ref={this.notifRef}
                  />
                  {
                    showErrorNotif === true && showErrorCountNotif === false && (
                      <InlineNotification lowContrast className="w-100" kind="error" title="Please fill all fields marked with *" subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showErrorNotif: false })} />
                    )
                  }
                  {
                    showErrorNotif === false && showErrorCountNotif === true && (
                      <InlineNotification lowContrast className="w-100" kind="error" title={`Please enter less than ${maxWords} words`} subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showErrorNotif: false })} />
                    )
                  }
                  <div className="bx--col bx--row m-0 pl-3">
                    <div className="pl-3 mt-3 form-label bx--col-lg-5 bx--type-zeta mb-1">{t('startedBusiness')}<span className="text-danger">*</span></div>
                    <div className="pl-2 pl-md-3 pr-2 pr-md-3">
                      <Toggle disabled={disabled} toggled={startedBusinessOutsideCourse} id="startedBusinessOutsideCourse" name="startedBusinessOutsideCourse" className="col-md" onToggle={this.onToggle} labelA={t('no')} labelB={t('yes')} />
                    </div>
                  </div>
                  {
                    startedBusinessOutsideCourse === true && (
                      <div className="bx--col m-0 pl-3">
                        <div className="pl-3 mt-3 form-label bx--type-zeta mb-1">{t('tellAboutBusiness')}<span className="text-danger">*</span></div>
                        <div className="pl-2 pl-md-3 pt-2 pr-2 pr-md-3">
                          <TextArea  disabled={!startedBusinessOutsideCourse || disabled} className="col-10" labelText="" hideLabel name="aboutBusiness" value={aboutBusiness || ''} onChange={this.handleText} id="aboutBusiness" />
                        </div>
                      </div>
                    )
                  }
                  <div className="bx--col m-0 pl-3">
                    <div className="pl-3 mt-3 form-label bx--type-zeta mb-1">
                      {t('ifSelectedGEIP')}
                      <span className="text-danger">*</span>
                    </div>
                    <div className="pl-2 pl-md-3 pt-2 pr-2 pr-md-3">
                      <TextArea  disabled={disabled} className="col-10" labelText="" hideLabel name="geipSelectionAchievement" value={geipSelectionAchievement || ''} onChange={this.handleText} id="geipSelectionAchievement" />
                    </div>
                  </div>
                  <div className="bx--col m-0 pl-3">
                    <div className="pl-3 mt-3 form-label bx--type-zeta mb-1">{t('convincePoints')}<span className="text-danger">*</span></div>
                    <div className="pl-2 pl-md-3 pt-2 pr-2 pr-md-3">
                      <TextArea   disabled={disabled} className="col-10" labelText="" hideLabel name="geipReasonToChoose" value={geipReasonToChoose || ''} onChange={this.handleText} id="geipReasonToChoose" />
                    </div>
                  </div>
                  <div className="bx--col m-0 pl-3">
                    <div className="pl-3 mt-3 form-label bx--type-zeta mb-1">{t('geipMarketQuestion')}<span className="text-danger">*</span></div>
                    {/* {marketingGeipQuestionInvalid && (<div className="pl-3 mb-1">Please select atleast one option</div>)} */}
                    <div className="pl-2 pl-md-3 pt-2 pr-2 pr-md-3">                    
                      {geipMarketCheckboxes && Array.isArray(geipMarketCheckboxes) && geipMarketCheckboxes.map((marketCheckbox) => {
                        const { label, value } = marketCheckbox || {};
                        return (
                          <div>
                            <Checkbox
                              disabled={disabled}
                              name={value}
                              id={value}
                              labelText={t(`${value}`,label)}
                              value={t(`${value}`)}
                              checked={selectedMarketCheckboxesValues && selectedMarketCheckboxesValues.includes(value)}
                              onChange={(isSelected) => {this.handleChangeMarketCheckbox(isSelected, value, label)}}
                            />
                          </div>
                        );
                      })}
                      {isOthers && (
                        <div className='' style={{marginLeft: "1.8rem"}}>
                          <TextArea
                            disabled={disabled}
                            className="col-10"
                            labelText={<span>{`${t("Others (pls elaborate)")}:`}<span className='text-danger'>*</span></span>}
                            name="othersAnswer"
                            value={othersAnswer || ''}
                            onChange={this.handleText}
                            id="othersAnswer"
                            // invalid={othersAnswerInvalid}
                            // invalidText="Please elaborate on others"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="bx--col m-0 pl-3">
                    <div className="pl-3 mt-3 form-label bx--type-zeta mb-1">{t('readyToWorkinAsean')}<span className="text-danger">*</span></div>
                    <div className="pl-2 pl-md-3 pt-2 pr-2 pr-md-3">
                      <RadioButtonGroup
                        legend=""
                        name="canWorkInAsean"
                        className="ml-3"
                        valueSelected={canWorkInAsean}
                        onChange={this.onRadioGroupChange}
                      >
                        <RadioButton
                          value="Yes"
                          id="Yes"
                          disabled={disabled}
                          labelText={t('yes')}
                        />
                        <RadioButton
                          value="No"
                          id="No"
                          disabled={disabled}
                          labelText={t('no')}
                        />
                        <RadioButton
                          value="Maybe"
                          id="Maybe"
                          disabled={disabled}
                          labelText={t('mayBe')}
                        />
                      </RadioButtonGroup>
                    </div>
                  </div> */}
                  {/* <div className="bx--col bx--row m-0 pl-3">
                    <div className="pl-3 mt-3 form-label bx--col-lg-5 bx--type-zeta mb-1">{t('planedOwnOverseastrip')}<span className="text-danger">*</span></div>
                    <div className="pl-2 pl-md-3 pr-2 pr-md-3">
                      <Toggle disabled={disabled} toggled={plannedOverseasTrip} id="plannedOverseasTrip" name="plannedOverseasTrip" className="col-md" onToggle={this.onToggle} labelA={t('no')} labelB={t('yes')} />
                    </div>
                  </div>
                  <div className="bx--col bx--row m-0 pl-3">
                    <div className="pl-3 mt-3 form-label bx--col-lg-5 bx--type-zeta mb-1">{t('travelledAbroadFamily')}<span className="text-danger">*</span></div>
                    <div className="pl-2 pl-md-3 pr-2 pr-md-3">
                      <Toggle disabled={disabled} toggled={travelledOverseasAlone} id="travelledOverseasAlone" name="travelledOverseasAlone" className="col-md" onToggle={this.onToggle} labelA={t('no')} labelB={t('yes')} />
                    </div>
                  </div> */}
                  {/* <div>
                    <Preferences ref="preferences" disabled={disabled} hide={["jobpreferences", 'jobMatches', "jobfunctions", "jobpreferences", "industries"]} setData={this.setPreferences} cityPreferences={cityPreferences} />
                  </div> */}
                  {/* <div className="bx--col ml-md-1">
                    <DropDownBox
                      title={t('preferredCities')}
                      required
                      disabled={disabled}
                      labelClass="bx--type-zeta mb-0"
                      titleClass="col-sm-12 col-md-3 col-lg-5 mt-2"
                      mainClassName="row m-0 pl-1"
                      options={allCities}
                      name="cities"
                      placeholder={t('cities')}
                      onChange={(id, value, name) => this.onDropdownChange(value, name)}
                      selectedValue={cities}
                      isMulti
                    />
                  </div> */}
                  {
                    disabled === false && (
                      <Fragment>
                        <hr className="mt-2 mb-2" />
                        {/* <div className="text-center w-100">
                          <Button className="mt-2" onClick={this.handleOnclickNextButton} small kind="primary">{t('next')}</Button>
                        </div> */}
                          <div className="d-flex w-100 justify-content-center mt-5">
                        <div>
                          <Button onClick={this.handlePreviousButtonClickBackToStep} className="mr-2" kind="tertiary" small>{t('previous')}</Button>
                        </div>
                        <div>
                          <Button onClick={this.handleOnclickNextButton} kind="primary" small>{t('next')}</Button>
                        </div>
                      </div>
                      </Fragment>
                    )
                  }
                </Fragment>
              )
          }
          {
            indexId!==null && indexId!==undefined && (Number(indexId) === 3)
              && (
                <Fragment>
                  <div>
                    {
                      isAppSubmitted !== true && (
                        <div className="font-weight-bold h5">
                          {t('geipMsg1')}
                        </div>
                      )
                    }
                  </div>
                  <PublicCandidateInfo
                    ref="candidateDetails"
                    canWorkInAsean={canWorkInAsean}
                    travelledOverseasAlone={travelledOverseasAlone}
                    plannedOverseasTrip={plannedOverseasTrip}
                    geipReasonToChoose={geipReasonToChoose}
                    geipSelectionAchievement={geipSelectionAchievement}
                    marketingGeipQuestion={marketingGeipQuestion}
                    aboutBusiness={aboutBusiness}
                    cityPreferences={cityPreferences}
                    startedBusinessOutsideCourse={startedBusinessOutsideCourse}
                    indexId={indexId}
                    push={push}
                    disabled={disabled}
                    handleValidationStages={this.handleValidationStages}
                    profileId={profileId}
                  />
                </Fragment>
              )
          }
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.x0paDuser.loading,
  candidateProfile: state.roboroyApplications.candidateProfile,
});

const mapDispatchToProps = {
  getCandidateProfile,
  getAllFaculties,
  getCitiesList,
  uploadImage,
  updatePictureUrl,
  editCandidateProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GeipDetails));